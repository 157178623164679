.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.child-wrapper {
  padding: 10px;
}

.login-bar {
  display: flex;
  margin-bottom: 10px;
}