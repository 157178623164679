.shipped-cards-table table, td, th {
    border: 1px solid black;
}

.shipped-cards-table table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 5px;
}

.shipped-cards-table td {
    text-align: center;
}

.cliploader {
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}